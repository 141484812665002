import React, { useState } from "react"
import logoPreto from '../../assets/images/logo-preto.png'
import { getItem } from '@utils'
import { ShowNotification, NotificationType } from '@utils'
import './styles.css';

const Lobby = ({ setScreenToShow, setUserType, setSessionId }) => {
    const [showPreview, setShowPreview] = useState(false)

    const handleEnableCameraPreview = async () => {
        setShowPreview(true)
        let stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
        let video = document.getElementById('preview-camera');
        video.srcObject = stream;
        video.play();
    }

    const handleJoinRoom = () => {
        const userType = getItem('USERTYPE')
        if(userType === 'agr'){
            setUserType(userType);
            setSessionId(getItem('SESSION'))
            ShowNotification(NotificationType.Warn, 'Sempre que gravar uma videoconferência precisa validar um código OTP novo')
        }
        
        setScreenToShow('videoconferencia')
    }

    return (
        <div className="card-container">
            <div className="card-header">
                <img className="img-logo" src={logoPreto} />
            </div>
            
            <div className="preview-container">
                {
                    showPreview ?
                    <video id={'preview-camera'} />
                    :
                    <button onClick={handleEnableCameraPreview} className="button-primary button-join-room">Habilitar câmera</button>
                }
            </div>
            
            <div className="card-bottom">
                <button onClick={handleJoinRoom} className="button-primary button-join-room">Entrar</button>
            </div>
        </div>
    )
}

export default Lobby